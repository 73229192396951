@import "src/styles/index.scss";

.page {
    @include internal-app-page;
}

.spinContainer {
    @include spin-container
}

.tabs {
    @include font-family;
    :global {
        .ant-tabs-nav {
            padding-left: 50px;
            background-color: white;
        }

        .ant-tabs-tab {
            font-size: 18px !important;
            font-weight: 400;
            color: #8e9fb3;
        }

        .ant-tabs-ink-bar {
            border-bottom: 4px solid #f31f1f;
        }
        .ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #02013f;
            font-weight: 600;
        }

        .ant-tabs-tab-btn {
            &:focus,
            &:active {
                color: #02013f;
            }
        }
    }
}
