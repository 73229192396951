@import "src/styles/index.scss";

.container {
    width: calc(100vw - 242px);
    margin-right: 70px;
    margin-left: 70px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 73px;
}

.content {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 4px 20px rgba(27, 67, 88, 0.05);
    padding-right: 60px;
    min-height: 530px;
    padding-left: 30px;
    padding-top: 40px;
}

.title {
    @include header-text-styles;
    margin-bottom: 28px;
    margin-bottom: 23px;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.buttonDisabled {
    color: white !important;
    background-color: #e8e8e8 !important;
    border-color: #e8e8e8 !important;
}

.menuItem {
    text-align: center;
}

.statusIcon {
    font-size: 18px;
    color: #bfbfbf;
}

.deleteIcon {
    font-size: 18px;
}

// Column styles
.header_documentName {
    width: 300px;
    padding: 16px;
    padding-left: 55px;
}
.header_documentType {
    width: 220px;
    padding: 16px;
}
.header_scanDate {
    width: 220px;
    padding: 16px;
}
.header_results {
    width: 220px;
    text-align: center;
    padding: 16px;
}
.header_step {
    width: 220px;
    padding: 16px;
}
.header_progress {
    width: 250px;
    padding: 16px;
}
.header_status {
    width: 100px;
    text-align: center;
    padding: 16px;
}
.header_action {
    width: 300px;
    text-align: center;
    padding: 16px;
}
.cell_documentName {
    width: 300px;
    word-break: break-all;
}
.cell_documentType {
    width: 220px;
    word-break: break-all;
}
.cell_scanDate {
    width: 220px;
    word-break: break-all;
}
.cell_results {
    width: 220px;
}
.cell_step {
    width: 220px;
    word-break: break-all;
}
.cell_progress {
    width: 250px;
}
.cell_status {
    width: 100px;
    justify-content: center;
}
.cell_action {
    width: 300px;
}

.table {
    :global {
        .ant-table-measure-row {
            display: none !important;
        }

        .ant-table-thead {
            height: 70px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            tr th {
                @include p-dark-header-text-styles;
                height: 70px;
                background-color: white;
                border-top-left-radius: 0px;
                border-bottom: unset;
                border-top: unset;
                line-height: 16px;
                padding: 0px;
            }
        }

        .ant-table-tbody {
            tr td {
                @include p-dark-text-styles;
                border-top: 0px;
                border-bottom: unset;
                height: 70px;
                display: flex;
                align-items: center;
            }
            tr {
                background-color: white;
                display: flex;
                align-items: center;
                border-top: 1px solid #e3e5ea;
            }
            tr.ant-table-row:hover {
                td {
                    background: unset !important;
                }
            }
        }

        .ant-table-content {
            &::-webkit-scrollbar {
                display: none;
            }
            scrollbar-width: none;
            cursor: default;
        }

        .ant-table-pagination {
            margin: 30px 0 68px 55px !important;
            width: fit-content;
            height: 45px;
            background: #ffffff;
            border: 1px solid #e3e5ea;
            padding: 5px 17px 5px 7px;
            box-sizing: border-box;
            border-radius: 35px;
            align-items: center;
            li {
                display: inline-block;
            }
            .ant-pagination-prev {
                display: none;
            }

            .ant-pagination-item-ellipsis {
                color: silver;
                cursor: pointer;
            }
            .anticon-double-left {
                cursor: pointer;
                &:hover {
                    color: #1f95f3;
                }
            }
            .anticon-double-right {
                cursor: pointer;
                &:hover {
                    color: #1f95f3;
                }
            }
            .ant-pagination-item {
                @include pagination-item-text;
                border: unset;
                display: flex;
                align-items: center;
                margin-right: 3px;
            }
            .ant-pagination-item a {
                width: 35px;
                height: 35px;
                line-height: 35px;
                padding: 0px;
            }
            .ant-pagination-item-active a {
                border-radius: 50%;
                width: 35px;
                height: 35px;
                line-height: 35px;
                background-color: #1f95f3;
                color: white;
                padding: 0px;
            }
        }
    }
}

.tablePaginationNextButton {
    @include pagination-item-text;
    margin-left: 18px;
}

.confirmationDialogueText {
    font-family: "Manrope", sans-serif;
    color: #262626;
}
