@import "src/styles/variables.scss";

/*** Styles ***/
html {
    min-height: 100%;
    display: flex;
    box-sizing: border-box; // simplifies setting dimensions of elements
}

body {
    width: 100%;
    flex: 1 1;
    margin: 0;
    padding: 0;
}

* {
    box-sizing: inherit;

    -webkit-font-smoothing: antialiased; // enable antialiasing for text
    -moz-osx-font-smoothing: grayscale;
}

#root,
.app-container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    overflow-x: hidden;
}

.app-page {
    min-height: 100%;
    padding: 0;
    flex: 1;

    background-color: $finscan-grey-bg;

    @include flex-col-center-x;
}

label {
    font-family: "Manrope", arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 14px !important;
    line-height: 24px;
    color: #585858 !important;
}

a {
    color: #2f80ed;
}
