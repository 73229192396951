@import "src/styles/index.scss";

.container {
	width: calc(100vw - 103px);
	padding-top: 0px !important;
	display: flex;
	flex-direction: column;
	position: absolute;
	left: 102px;
	background: #ffffff;
	margin-left: 20px;
}

.button {
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
	background-color: white;
	color: #1f95f3;
}

.searchBar {
	input {
		&::placeholder {
			@include font-family;
			color: #8e9fb3 !important;
			font-weight: 400;
		}
	}
}

.searchIcon {
	color: #8e9fb3;
	font-size: 15px;
	margin-right: 18px;
}

.title {
	@include header-text-styles;
	margin-bottom: 34px;
	padding-top: 51px;
	padding-left: 70px;
	font-size: 32px;
}

.header {
	width: 300px;
	padding: 16px;
}

.headerLink {
	width: 70px;
	padding: 16px;
}

.cellKey {
	color: #02013f !important;
	width: 300px;
	word-break: break-all;
}

.cell {
	width: 300px;
	word-break: break-all;
}

.cellLink {
	width: 70px;
	word-break: break-all;
}

.table {
	:global {
		// .ant-table-measure-row {
		// 	display: none !important;
		// }

		.ant-table-thead {
			align-items: center;

			tr th {
				@include p-light-grey-text-styles;
				font-weight: 600;
				font-size: 12px;
				// 	height: 70px;
				background-color: white;
				// 	border-top-left-radius: 0px;
				// 	border-bottom: unset;
				// 	border-top: unset;
				// 	line-height: 16px;
				// 	padding: 0px;
			}
		}

		.ant-table-tbody {
			tr td {
				@include p-light-grey-text-styles;
				font-weight: 600;
				//border-top: 0px;
				//border-bottom: unset;
				//height: 70px;
				//display: flex;
				align-items: center;
			}
			tr.ant-table-row:hover {
				td {
					background: unset !important;
				}
			}
		}

		.ant-table-placeholder {
			display: flex;
			justify-content: center;
		}

		.ant-table-content {
			&::-webkit-scrollbar {
				display: none;
			}
			scrollbar-width: none;
			cursor: default;
		}

		.table-row-light {
			background-color: white;
			align-items: center;
		}
		.table-row-dark {
			background-color: #f6f7f9;
			align-items: center;
		}

		.ant-table-pagination {
			margin: 30px 0 68px 55px !important;
			width: fit-content;
			height: 45px;
			background: #ffffff;
			border: 1px solid #e3e5ea;
			padding: 5px 17px 5px 7px;
			box-sizing: border-box;
			border-radius: 35px;
			align-items: center;
			li {
				display: inline-block;
			}
			.ant-pagination-prev {
				display: none;
			}

			.ant-pagination-item-ellipsis {
				color: silver;
				cursor: pointer;
			}
			.anticon-double-left {
				cursor: pointer;
				&:hover {
					color: #1f95f3;
				}
			}
			.anticon-double-right {
				cursor: pointer;
				&:hover {
					color: #1f95f3;
				}
			}
			.ant-pagination-item {
				@include pagination-item-text;
				border: unset;
				display: flex;
				align-items: center;
				margin-right: 3px;
			}
			.ant-pagination-item a {
				width: 35px;
				height: 35px;
				line-height: 35px;
				padding: 0px;
			}
			.ant-pagination-item-active a {
				border-radius: 50%;
				width: 35px;
				height: 35px;
				line-height: 35px;
				background-color: #1f95f3;
				color: white;
				padding: 0px;
			}
		}
	}
}

.tablePaginationNextButton {
	@include pagination-item-text;
	margin-left: 18px;
}
