@import "src/styles/index.scss";

.backNavigation {
    width: calc(100vw - 102px);
    height: 70px;
    padding: 25px 70px;
    border-bottom: 1px solid #e3e5ea;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    z-index: 2;
    position: absolute;
    top: 0;
}

.button {
    @include p-light-grey-text-styles;
    background: transparent;
    border: none;
    box-shadow: none;
    text-shadow: none;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-left: 0px;
    &:hover {
        background: transparent;
        color: #8e9fb3;
    }
}
.icon {
    width: 19px;
    height: 19px;
    float: left;
    margin-right: 15px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-image: url("../../../../assets/img/back-icon.svg");
}
