
@import "src/styles/variables.scss";
@import "src/styles/index.scss";


.page {
    @include internal-app-page;
}

.docUpload {
    width: calc(100vw - 242px);
    margin-right: 70px;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 73px;
}

.title {
    @include header-text-styles;
    margin-bottom: 28px;
}

.text {
    @include p-dark-grey-text-styles;
    width: 223px;
    margin: auto !important;
}

.content {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 4px 20px rgba(27, 67, 88, 0.05);
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
}

.sectionBottom {
    padding-top: 15px;
    width: 90%;
    flex: 1;
}

.infoText {
    @include font-family;
    max-width: 751px;
    width: 100%;
    line-height: 26px;

    span {
        color: blue;
        font-weight: bold;
    }
}

.faqSection {
    //margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

// Font color not working with antd for some reason...
.faqQuestion {
    color: $finscan-dark-text-color;
   font-weight: 600;
}

.faqAnswer {
   white-space: "pre-line";
   padding-left: 24px;
   font-weight: normal;
}

.subtitle {
    margin-bottom: 22px;
    text-align: center;
}
