// Please don't change the order of imports.
@import "./variables.scss";
@import "./mixins.scss";
@import "./sharedStyles.scss";
@import "./keyframes.scss";

@font-face {
    font-family: "Manrope";
    src: local("Manrope"), url(../assets/fonts/Manrope-VariableFont_wght.ttf) format("ttf");
}

@font-face {
    font-family: "AsapRegular";
    src: url(../assets/fonts/Asap-Regular.ttf) format("ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "AsapMedium";
    src: url(../assets/fonts/Asap-Medium.ttf) format("ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "AsapSemiBold";
    src: url(../assets/fonts/Asap-SemiBold.ttf) format("ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "AsapBold";
    src: url(../assets/fonts/Asap-Bold.ttf) format("ttf");
    font-weight: normal;
    font-style: normal;
}
