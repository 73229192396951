@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* Animations */
@keyframes fade-in {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes fade-out {
	0% { opacity: 1; }
	100% { opacity: 0; }
}

@keyframes pulse {
	0% { transform: scale(1); }
	50% { transform: scale(1.08); }
	100% { transform: scale(1); }
}
