@mixin font-family {
    font-family: "Manrope", sans-serif;
}

@mixin p-light-grey-text-styles() {
    @include font-family;
    font-size: 14px;
    color: $finscan-light-grey-text-color;
}

@mixin p-medium-grey-text-styles() {
    @include font-family;
    font-size: 14px;
    color: $finscan-medium-grey-text-color
}

@mixin p-dark-grey-text-styles() {
    @include font-family;
    font-size: 14px;
    color: $finscan-dark-grey-text-color;
}

@mixin p-dark-text-styles() {
    @include font-family;
    font-size: 16px;
    color: $finscan-dark-text-color;
}

@mixin p-dark-header-text-styles() {
    @include p-dark-text-styles;
    font-weight: 700;
}

@mixin header-text-styles() {
    @include p-dark-header-text-styles;
    font-size: 21px;
}

@mixin blue-text-button {
    @include font-family;
    font-weight: 600;
    font-size: 16px;
    color: $finscan-blue-color;
}

@mixin pagination-item-text {
    @include p-dark-text-styles;
    font-weight: 500;
    font-size: 14px;
}

@mixin error-text-styles {
    @include font-family;
    font-weight: 300;
    color: red;
}

@mixin self-center-x {
    left: 0;
    right: 0;
    margin: auto; // required for Safari and old Chrome
}

@mixin self-center-y {
    top: 0;
    bottom: 0;
    margin: auto; // required for Safari and old Chrome
}

@mixin box-shadow {
    border-radius: 6px;
    box-shadow: 0 2px 13px rgba(1, 57, 90, 0.05);
    background: #fff;
}
@mixin box-shadow-strong {
    border-radius: 6px;
    box-shadow: 0 2px 13px rgba(1, 57, 90, 0.12);
    background: #fff;
}

@mixin flex-row {
    display: flex;
}
@mixin flex-row-center {
    display: flex;
    justify-content: center;
}
@mixin flex-row-center-y {
    display: flex;
    align-items: center;
}
@mixin flex-row-center-x-y {
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin flex-row-space-between {
    display: flex;
    justify-content: space-between;
}
@mixin flex-row-space-evenly {
    display: flex;
    justify-content: space-evenly;
}

@mixin flex-col {
    display: flex;
    flex-direction: column;
}
@mixin flex-col-center-x {
    display: flex;
    flex-direction: column;
    align-items: center;
}
@mixin flex-col-center-y {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@mixin flex-col-center-x-y {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@mixin flex-col-space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

// Generic page styles

@mixin app-page() {
    width: 100%;
    min-height: 100%;
    max-height: 100vh;
    flex: 1;
    background-color: $finscan-grey-bg;
}

@mixin internal-app-page() {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    padding-left: 85px;
    min-height: 100vh;
    background-color: $finscan-grey-bg;
}

@mixin spin-container() {
    padding-left: 102px;
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
}

@mixin section-right {
    overflow-x: hidden;
    overflow-y: auto;
}

@mixin default-background-size() {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

@mixin menu-icon {
    min-width: 26px;
    min-height: 26px;
    font-size: 22px;
    @include default-background-size;
}

@mixin button {
    @include font-family;
    width: 143px;
    height: 40px;
    border-radius: 56px;
    color: white;
    background-color: #1f95f3;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    border: white;
    margin-top: 10px;
}
