@import "src/styles/index.scss";

.docUpload {
    width: calc(100vw - 242px);
    margin-right: 70px;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 73px;
}

.title {
    @include header-text-styles;
    margin-bottom: 28px;
}

.text {
    @include p-dark-grey-text-styles;
    width: 223px;
    margin: auto !important;
}

.buttonWhite {
    @include button;
    box-shadow: 0px 4px 20px rgba(27, 67, 88, 0.05);
    background-color: white !important;
    color: #1f95f3 !important;
    margin-top: 15px !important;
    margin: auto;
}

.buttonBlue {
    @include button;
}

.uploadButton {
    @include blue-text-button;
    background: transparent;
    border: none;
    box-shadow: none;
    text-shadow: none;
    display: flex;
    align-items: center;
}
.uploadIcon {
    width: 22px;
    height: 16px;
    float: left;
    margin-right: 15px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-image: url("../../../../assets/img/upload-icon.svg");
}

.content {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 4px 20px rgba(27, 67, 88, 0.05);
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
}

.sectionTop {
    @include font-family;
    padding: 10px;
    padding-left: 214px;
    padding-right: 214px;
    border-bottom: 1px solid #e2e5e7;
    text-align: center;
}

.sectionBottom {
    padding-top: 15px;
    width: 90%;
    flex: 1;
}

.infoText {
    @include font-family;
    max-width: 751px;
    width: 100%;
    line-height: 26px;

    span {
        color: blue;
        font-weight: bold;
    }
}

.documentUploadSection {
    //margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.subtitle {
    margin-bottom: 22px;
    text-align: center;
}

.dragDropContainer {
    position: relative;
    height: 368px !important;
    margin-bottom: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #c2c2d2 !important;
    border-radius: 10px !important;
}

.boxIcon {
    width: 48px;
    height: 54px;
    background-image: url(../../../../assets/img/document-upload-icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin: auto;
    margin-bottom: 30px;
}

.documentsList {
    height: 285px;
    max-height: 285px !important;

    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
        padding-left: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 3px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #c7c7c7;
        border-radius: 3px;
    }
    scrollbar-width: none;
    border-top: 1px solid #e3e5ea;

    .listInner {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 2px;
    }

    .infoMessage {
        opacity: 0.5;

        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        text-align: center;
        color: #606089;
    }
}

.message {
    min-height: 22px;
    height: auto;
    margin-bottom: 10px;
    font-weight: 500;
}