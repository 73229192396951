@import "src/styles/index.scss";

.page {
    @include app-page;
    @include flex-col-center-x-y;
}

.logo {
    position: absolute;

    width: 40px;
    height: 49px;
    top: 17px;
    left: 25px;

    background-image: url("../../../../assets/img/logo-teylor.svg");
    background-size: 40px 49px;
}

.form {
    position: relative;

    padding: 70px 200px 10px 200px;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px -2px 4px rgba(0, 0, 0, 0.1), 2px 0px 4px rgba(0, 0, 0, 0.1),
        -2px 0px 4px rgba(0, 0, 0, 0.05);

    background: #ffffff;

    @include flex-col-center-x-y;
    // @include box-shadow;
}

.title {
    @include p-medium-grey-text-styles;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0em;
    font-weight: 600;
    text-align: center;
    text-align: center;
    margin-bottom: 20px;
}

.inputEmail {
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 2px;
    width: 319px;
    height: 32px;
}

.inputPassword {
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 2px;
    width: 319px;
    height: 32px;
}

.text {
    @include p-medium-grey-text-styles;
    width: 319px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 30px;
}

label::before {
    display: none !important;
}

.formItem label {
}

.topRight {
    position: absolute;
    top: 20px;
    right: 56px;
}

.button {
    @include font-family;
    color: #ffffff;
    background: #2f80ed;
    border-radius: 2px;
    width: 107px;
    height: 32px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 22px;
    text-align: center;
    margin-top: 10px;
}

.ant-form-item-explain {
    @include error-text-styles;
}

.error {
    @include error-text-styles;
    text-align: center;
    width: 319px;
    height: 70px;
}
