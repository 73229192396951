@import "src/styles/index.scss";

.ant-tooltip {
    z-index: 10000;
    margin-left: 20px;
}
.sideMenu {
    z-index: 10;
    background-color: #222222;
    position: fixed;
    width: 102px;
    max-width: 102px;
    top: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0 14px 0px;
}

.cornerTop {
    position: absolute;
    top: 0px;
    left: 102px;
    height: 40px;
    width: 43px;
    border-top-left-radius: 50%;
    box-shadow: 0 -20px 0 0 #222222;
}

.cornerBottomContainer {
    position: absolute;
    bottom: 0px;
    left: 102px;
    height: 40px;
    width: 43px;
    padding-bottom: 20px;
    overflow: hidden;
}

.cornerBottom {
    height: 40px;
    width: 43px;
    border-bottom-left-radius: 50%;
    color: #c2c2c2;
    box-shadow: 0 20px 0 0 #222222;
}

.containerLogo {
    display: flex;
    margin-bottom: 70px;
}

.logo {
    width: 22px;
    height: 26px;
    background-image: url("../../../../assets/img/logo-teylor-side-menu.svg");
    margin-bottom: 70px;
}

.containerBottom {
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
}

.menuItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    cursor: pointer;
}

.icon {
    @include menu-icon;
}

.label {
    @include font-family;
    color: #575757;
    font-weight: 700;
    text-align: center;
    margin: auto;
}

@media only screen and (max-height: 700px) {
    .sideMenu {
        overflow-y: scroll;
        overflow-x: hidden;
    }
}
