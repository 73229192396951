@import "src/styles/index.scss";

.fileItem {
    width: 100%;
    min-height: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    background-color: white;
    animation: fadein 0.3s ease-out forwards;
}

.icon {
    margin-right: 23px;
    color: #1F95F3;
    font-size: 16px;
}

.name {
    @include p-dark-text-styles;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.delete {
    width: 20px;
    height: 19px;
    margin-right: 10px;
    background-image: url(../../../../assets/img/delete-icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    cursor: pointer;
}

.visible {
    width: 19px;
    height: 21px;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-right: 10px;
    background-image: url(../../../../assets/img/visible-icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.hidden {
    width: 19px;
    height: 21px;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-right: 10px;
    background-image: url(../../../../assets/img/hidden-icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.buttons {
    display: flex;
    align-items: center;
    span {
        margin-right: 8px;
    }
}

.docType {
    @include font-family;
    width: 130px;
    margin-right: 20px;
    background-color: white;
    color: #1890FF;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
}
