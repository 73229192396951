@import "src/styles/index.scss";

.page {
    @include internal-app-page;
}

.container {
    width: calc(100vw - 172px);
    margin-right: 70px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 73px;
    padding-left: 70px;
}

.content {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 4px 20px rgba(27, 67, 88, 0.05);
    padding: 40px 40px 60px 40px;
}

.documentSection {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.document {
    width: 48%;
    max-height: 900px;
    overflow-x: scroll;
    box-shadow: 0px 4px 10px rgba(27, 67, 88, 0.05);

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        padding-left: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 3px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #c7c7c7;
        border-radius: 3px;
    }
}

.table {
    width: 48%;
    max-height: 900px;

    :global {
        .ant-table-content {
            overflow: auto !important;
            max-height: 900px;

            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
                padding-left: 2px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                border-radius: 3px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #c7c7c7;
                border-radius: 3px;
            }
        }

        .ant-table-thead {
            tr th {
                @include font-family;
                font-weight: 700;
                font-size: 14px;
                line-height: 22px;
                color: #262626;
                height: 70px;
            }
        }

        .ant-table-tbody {
            tr td {
                @include font-family;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                border-top: 0px;
                border-bottom: unset;
                height: 70px;
            }
            tr td:nth-child(odd) {
                background: #e8e8e8 !important;
            }
            tr td:nth-child(even) {
                background: white !important;
            }
        }
    }
}

.row {
    &:focus {
        background-color: white;
    }
}

.rowTitle {
    background-color: #fafafa !important;
    &:hover {
        td {
            background-color: #fafafa !important;
        }
    }
    :global {
        .ant-table-cell {
            border-bottom: 1px solid #e8e8e8 !important;
            font-weight: 600 !important;
            color: #262626 !important;
        }
    }
}

.headerSmall {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
}

.cellSmall {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
}

.headerBig {
    width: 170px;
    max-width: 170px;
    min-width: 170px;
}

.cellBig {
    width: 170px;
    max-width: 170px;
    min-width: 170px;
}

.input {
    @include font-family;
    background-color: white !important;
    border: unset;
    padding: 0px;
    border-bottom: 2px solid #e3e5ea;
    color: #595959 !important;
    font-size: 14px;
    line-height: 22px !important;
    font-weight: 400;
    resize: none;
    &:focus {
        box-shadow: none !important;
    }
}

:global {
    .ant-input-disabled {
        background-color: white !important;
        color: #c7c7c8 !important;
        border-bottom: 2px solid white !important;
    }
}

.buttonBlue {
    @include button;
    float: right;
    margin-top: 40px !important;
}
