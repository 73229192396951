@import "src/styles/index.scss";

.pageHeader {
    //height: 270px;
    background: white;
    padding: 30px 0px 25px 50px;
}

.title {
    @include header-text-styles;
    font-size: 36px;
   // line-height: 55px;
    letter-spacing: -0.04em;
    text-align: left;
}

.subtitleContainer {
    display: inline-flex;
    padding-top: 5px;
    align-items: center;
}

.subtitle {
    @include p-dark-text-styles;
    font-size: 18px;
   // line-height: 21px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-left: 2px;
}

.copyContainer {
    display: inline-flex;
    align-items: center;
    margin-left: 5px;
}

.copy {
    font-size: 18px;
    margin-left: 20px;
}
