@import "src/styles/index.scss";

.container {
    width: 60%;
    margin: auto;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 73px;
    background-color: #ffffff;
}

.title {
    @include p-medium-grey-text-styles;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.button {
    width: 150px;
    height: 35px;
    margin-top: 30px;
}

.confirmationDialogueText {
    @include font-family;
    color: #262626;
}